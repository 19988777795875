import React from "react";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import CategoryTemplate from "../../../templates/category-page";

const coins = ["dogecoin", "shiba-inu", "dogelon-mars", "baby-doge-coin"];

const title = "Meme-Coins";
const Page: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={["Meme-Coins"]} title={title} />
      <CategoryTemplate title={title} coins={coins} coingeckoId="meme-token" />
    </Layout>
  );
};

export default Page;
